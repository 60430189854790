import {sendRequest} from '@api/request';
import {
  API_URI,
  BASE_URL_SERVER_API,
  jsonTypeRequestHeaders,
  simpleAuthRequestHeaders
} from '@constants';
import {
  AuthLoginUserParams,
  AuthLoginUserResponse,
  getMarketPVZParams, getMarketPVZResponse,
  ServiceFetchType,
  signUpNewUserParams, signUpNewUserResponse, signUpSendSMSParams, signUpSendSMSResponse
} from '@typings';
import Axios from "axios";

export const loginUser: ServiceFetchType<AuthLoginUserResponse, AuthLoginUserParams> = ({
                                                                                          params,
                                                                                        }) => {
  return sendRequest<AuthLoginUserResponse>(API_URI.LOGIN, {
    method: 'POST',
    data: params,
    headers: jsonTypeRequestHeaders,
  });
};

export const getMarketPVZ: ServiceFetchType<getMarketPVZResponse, getMarketPVZParams> = ({
                                                                                           params,
                                                                                         }) => {
  return sendRequest<getMarketPVZResponse>(API_URI.GET_MARKET_PVZ + `?CompanyCode=${params!.CompanyCode}` as API_URI, {
    method: 'POST',
  });
};

export const signUpNewUser: ServiceFetchType<signUpNewUserResponse, signUpNewUserParams> = ({
                                                                                              params,
                                                                                            }) => {
  return sendRequest<signUpNewUserResponse>(API_URI.SIGN_UP_NEW_USER, {
    method: 'POST',
    data: params,
    headers: {...jsonTypeRequestHeaders, ...simpleAuthRequestHeaders},
  });
};

export const signUpSendSMS: ServiceFetchType<signUpSendSMSResponse, signUpSendSMSParams> = ({
                                                                                              params,
                                                                                            }) => {
  return Axios(`${BASE_URL_SERVER_API}${API_URI.SIGN_UP_SEND_CODE}?UserName=${params!.UserName}`, {
    method: 'POST',
    headers: simpleAuthRequestHeaders,
  })
};

export const resetPassword: ServiceFetchType<signUpSendSMSResponse, signUpSendSMSParams> = ({
                                                                                              params,
                                                                                            }) => {
  return Axios(`${BASE_URL_SERVER_API}${API_URI.RESET_PASSWORD}?UserName=${params!.UserName}`, {
    method: 'POST',
    headers: simpleAuthRequestHeaders,
  })
};
