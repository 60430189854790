import {getEnvValue} from '@utils/getEnvValue';

export const BASE_URL_SERVER = getEnvValue('REACT_APP_GT_SD_BASE_URL_SERVER', 'https://api.l4y.ru/');
export const BASE_URL_SERVER_API = `${BASE_URL_SERVER}v3/`;

export const DADATA_API_KEY = '864c4422fa0e7f050a37d04c1dc17ef57bb4f879';
export const jsonTypeRequestHeaders = {
  'Content-Type': 'application/json',
};

export const formDataTypeRequestHeaders = {
  'Content-Type': 'multipart/form-data',
};

export const simpleAuthRequestHeaders = {
  'X-API-KEY-AUTH': getEnvValue('REACT_APP_SIMPLE_AUTH_KEY'),
  'Content-Length': '0',
};

export enum API_URI {
  LOGIN = 'api/gen/login/',
  GET_MARKET_PVZ = 'api/gen/getmarketpvz/',
  SIGN_UP_NEW_USER = 'api/Auth/registr/',
  SIGN_UP_SEND_CODE = 'api/Auth/gencodeforreg',
  RESET_PASSWORD = 'api/Auth/restorepwd',
  CREATE_ORDER = 'api/gen/createdelivery/',
  CALCULATE_ORDER = 'api/gen/calcone/',
  GET_DELIVERY_COMPANIES = 'api/gen/tarifflist/',
  GET_PVZ_LIST = 'api/ext/getpvzforcoord/',
  GET_PVZ_ALL = 'api/ext/getpvzall/',
  GET_ORDERS_LIST = 'api/gen/getlistdelivery/',
  GET_ORDER_LABEL = 'api/gen/getlabel/',
  CANCEL_PARCEL = 'api/gen/deleteparcel',
  CREATE_TRACKING_INFO = 'api/ext/getstatusforparcel/',
  CREATE_TRACKING_SIMPLE_INFO = 'api/ext/getstatusforparcelsimple',
  REQUEST_DETAIL = 'api/gen/getdeliveryinfo/',
}
